import React, { useEffect, useState } from "react";
import _t from "../../../languages/translate";
import { calclocationCircle } from "./helper";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Badge, Spin, Divider } from "antd";
import { emptArr } from "../../helper/helperMethods";
import { handleEx } from "../../helper/handleException";
import { getWorkPlaces } from "../../../services/WorkPlaceService";

const PageRegistration = ({ onHandleData, handleObjLocation }) => {
  const [messageState, setMessageState] = useState(
    _t("strYouCannotRegistrate")
  );
  const [locationState, setLocationState] = useState(false);
  const [loadWP, setLoadWP] = useState(true);

  const MINUTE_MS = 3000;
  useEffect(() => {
    const interval = setInterval(() => {
      getLocation();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  let locList;

  useEffect(() => {
    async function feachData() {
      locList = await getWP();
    }
    feachData();
  }, []);

  const getWP = async () => {
    try {
      const _data = await getWorkPlaces();
      debugger;
      if (emptArr(_data?.data?.data, null) === null) {
        message.warning(_t("msgYouDontHaveAnyLocationInYourAcc"), 5);
        return null;
      } else {
        return _data?.data?.data;
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const showPosition = async (position) => {
    let arr = [];
    if (locList !== null) {
      try {
        for (let i = 0; i < locList.length; i++) {
          let isValid = await calclocationCircle(
            position.coords.accuracy,
            position.timestamp,
            position.coords.longitude,
            position.coords.latitude,
            locList[i].centerLongitude,
            locList[i].centerLatitude,
            locList[i].radius
          );

          if (isValid) {
            arr.push(locList[i]);
          }
        }
        if (arr.length > 0) {
          handleObjLocation(arr[0]);
          await setLocationState(true);
          await setMessageState(_t("strYouCanRegistrate") + ": " + arr[0].name);
          await onHandleData(true);
        } else {
          await setLocationState(false);
          await setMessageState(
            _t("strYouCannotRegistrate") +
              "|Accuracy: " +
              position.coords.accuracy
          );
          await onHandleData(false);
        }
      } catch (error) {
        handleEx(error);
      }
    }
  };

  const getLocation = async () => {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(showPosition, showError, {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0,
      });
    } else {
      message.error(_t("strGeolocationNotSupportedByThisBrowser"), 5);
    }
  };

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message.error(_t("strBrowserDoesNotHaveAbilityLocation"), 5);
        break;
      case error.POSITION_UNAVAILABLE:
        message.error(_t("strGeoLocationInformationNotAvailable"), 5);
        break;
      case error.TIMEOUT:
        message.error(_t("strRequestLocationTimedOut"), 5);
        break;
      case error.UNKNOWN_ERROR:
        message.error(_t("msgUnknownError"), 5);
        break;
    }
  }

  return (
    <div style={{ textAlign: "start" }}>
      {locationState ? (
        <Badge status="processing" text={messageState} />
      ) : (
        <Badge text={messageState} color="red" />
      )}

      <Divider dashed style={{ margin: "5px 0" }} />
    </div>
  );
};

export default PageRegistration;
